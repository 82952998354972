/* app.scss */
@import "../vendor/bootstrap-sass/assets/stylesheets/_bootstrap";
@import "../vendor/font-awesome/scss/font-awesome";

$public-back-color: #F2FAF8;
$public-fore-color: #0A5F5F;

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}
html {
    position: relative;
    min-height: 100%;
    overflow-y: scroll;
}
body {
    margin-bottom: 20px;
}
body.public {
  background-image: url(/img/krakow.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $public-back-color;
  color: black;
}
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20px;
    background-color: darken($public-back-color, 5%);
    color: $public-fore-color;
    text-align: center;
}
div.tooltip-wrapper {
    display: inline-block;
}

.btn[disabled] {
    background-color: lightgrey;
}


//$icons: (
//        chevron-left,
//        chevron-right,
//        chevron-top,
//        chevron-bottom
//);
//
//.glythicon {
//    @extend .fa;
//
//  @each $icon in $icons {
//
//    &.glythicon-#{$icon} {
//      @extend .fa-#{$icon};
//    }
//
//  }
//
//}

.glyphicon {
  @extend .fa;
  // Overloading "glyphicon-chevron-left" with "fa-arrow-left".
  &.glyphicon-chevron-left {
    @extend .fa-chevron-left;
  }

  // Overloading "glyphicon-chevron-right" with "fa-arrow-right".
  &.glyphicon-chevron-right {
    @extend .fa-chevron-right;
  }
}

/* navbar */
$normal-back: $public-fore-color;
$normal-fore: $public-back-color;
$active-back: $public-back-color;
$active-fore: $public-fore-color;
$hover-back: lighten($public-fore-color, 3%);
$hover-fore: darken($public-back-color, 3%);

@media (max-width: 900px) {
  .navbar-header {
    float: none;
  }
  .navbar-left,.navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin-top: 7.5px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in{
    display:block !important;
  }
}

.public .navbar-nav > li > a {
  outline: none;
}

.public .navbar-default {
    padding-top: 10px;
    background-color: $normal-back;
    border-color: $normal-back $normal-fore $normal-fore $normal-fore;
    border-radius: 0 0 10px 10px;
    //margin: 0 2em 2em 2em;
}
/* title */
.navbar-default .navbar-brand {
    margin: -15px 30px 0px 0px;
}
.public .navbar-default .navbar-brand {
    margin: -20px 30px 0px 0px;
}
.public .navbar-default .navbar-brand:hover,
.public .navbar-default .navbar-brand:focus {
    color: $normal-fore;
}
/* link */
.public .navbar-default .navbar-nav > li > a {
    border-radius: 10px 10px 0 0;
    background-color: $normal-back;
    color: $normal-fore;
    //font-weight: bold;
}
.public .navbar-default .navbar-nav > li > a:hover,
.public .navbar-default .navbar-nav > li > a:focus {
    background-color: $hover-back;
    color: $hover-fore;
}
.public .navbar-default .navbar-nav > .active > a,
.public .navbar-default .navbar-nav > .active > a:hover,
.public .navbar-default .navbar-nav > .active > a:focus {
    background-color: $active-back;
    color: $active-fore;
}
.public .navbar-default .navbar-nav > .open > a,
.public .navbar-default .navbar-nav > .open > a:hover,
.public .navbar-default .navbar-nav > .open > a:focus {
    background-color: $active-back;
    color: $active-fore;
}
/* caret */
.public .navbar-default .navbar-nav > .dropdown > a .caret {
    border-top-color: $normal-fore;
    border-bottom-color: $normal-fore;
}
.public .navbar-default .navbar-nav > .dropdown > a:hover .caret,
.public .navbar-default .navbar-nav > .dropdown > a:focus .caret {
    background-color: $hover-back;
    color: $hover-fore;
}
.public .navbar-default .navbar-nav > .open > a .caret,
.public .navbar-default .navbar-nav > .open > a:hover .caret,
.public .navbar-default .navbar-nav > .open > a:focus .caret {
    background-color: $active-back;
    border-top-color: $active-fore;
    border-bottom-color: $active-fore;
}
.public .navbar-default .navbar-toggle {
    background-color: $normal-fore;
    border-color: $normal-back;
}
.public .navbar-default .navbar-toggle:hover,
.public .navbar-default .navbar-toggle:focus {
    background-color: $normal-back;
}
.public .navbar-default .navbar-toggle .icon-bar {
}
//@media (max-width: 767px) {
.public .navbar-default .navbar-nav .dropdown-toggle {
    background-color: $normal-back;
    color: $normal-fore;
}
.public .navbar-default .navbar-nav .open .dropdown-menu {
    margin: -1px;
    margin-top: -5px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $normal-fore;
    border-top-width: 0;
    border-color: $normal-back;
    border-radius: 10px 0 10px 10px;
}
.public .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    background-color: $normal-fore;
    color: $normal-back;
}
.public .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
.public .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    background-color: $normal-back;
    color: $normal-fore;
}
//}
/* private.css */
.private {
    /*background-color: #facedd;*/
}

.descr {
    @extend .bg-warning;
}

//.table {
//  width: auto;
//}
//
//.table>tbody>tr {
//  cursor: pointer;
//}
//
.table.table-hover>tbody>tr:hover {
    color: blue;
}
//
//.table.table-hover>tbody>tr:hover,
//.table.table-hover>thead>tr>th:hover {
//  color: blue;
//  background-color: #ecf3f8;
//}

[st-sort] {
    cursor: pointer;
}

[st-sort]:before {
    @extend .fa;
    @extend .fa-fw;
    @extend .fa-sort;
}

.st-sort-ascent:before {
    @extend .fa;
    @extend .fa-fw;
    @extend .fa-sort-asc;
    color: blue;
}

.st-sort-descent:before {
    @extend .fa;
    @extend .fa-fw;
    @extend .fa-sort-desc;
    color: blue;
}

.header {
    display: table;
    width: 100%;
    color: $public-fore-color;
    background-color: $public-back-color;
    //min-height: 180px;
}

.site-title-container {
    display: table;
    float: left;
    //min-height: 180px;
    padding: 15px;
}

.site-title {
    display: table-cell;
    vertical-align: middle;
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
    font-weight: bold;
    font-size: 36px;
}

.logo-left {
    float: left;
}

.logo-right {
    float: right;
}

.block:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
}

.centered {
    display: inline-block;
    vertical-align: middle;
    width: 300px;
}

.locale-menu {
    margin-right: 10px;
}

.locale-select img {
    float: right;
}

[ui-view].main {
    //border: 1px solid red;
}

[ui-view].main.ng-enter, [ui-view].main.ng-leave {
    transition: all 0.5s ease-in-out;
}

[ui-view].main.ng-enter {
    position: absolute;
    opacity: 0;
}

[ui-view].main.ng-enter-active {
    position: absolute;
    opacity: 1;
}

[ui-view].main.ng-leave {
    position: absolute;
    opacity: 1;
}

[ui-view].main.ng-leave-active {
    position: absolute;
    opacity: 0;
}

.billet {
    background-color: $public-back-color;
    border-radius: 10px 10px 10px 10px;
    padding: 10px;
    margin-bottom: 10px;
}

.calendar {
    width: 100%;
}

.task-green button {
    background-color: green;
    color: white;
}

.task-red button {
    background-color: red;
    color: white;
}

.task-blue button {
    background-color: rgb(100, 100, 200);
    color: white;
}

/*editor.scss*/

.diff ins {
  background-color: #e6ffe6;
}

.diff del {
  background-color: #ffe6e6;
}

/*properties.scss*/

i.property {
  @extend .fa;
  color: black;
  margin-left: 2px;
  margin-right: 2px;
}

i.property-edited {
  color: orangered;
}

i.property-unedited {
  color: gray;
}

div.property {
  display: inline-block;
}

$properties: (
  title:   star,
  content: sticky-note
);

@each $property, $icon in $properties {

  i.property-#{$property} {
    @extend i.property;
  }

  i.property-#{$property}-edited {
    @extend i.property-#{$property};
    @extend i.property-edited;
    @extend .fa-#{$icon};
  }

  i.property-#{$property}-unedited {
    @extend i.property-#{$property};
    @extend i.property-unedited;
    @extend .fa-#{$icon}-o;
  }

  div.property-#{$property} {
    @extend div.property;
  }

  label i.property-#{$property} {
    @extend .fa;
    @extend .fa-#{$icon};
    color: black;
  }

}

/*table-editions.scss*/
table.editions {
  width: 100%;
}

table.editions td {
  padding: 10px;
}

table.editions div.first-column-witdh {
  width: 28ex;
}

table.editions td.locale-expanded {
  width: auto;
  text-align: left;
}

table.editions th.locale-expanded label {
  width: 100%;
  text-align: center;
}

table.editions td.locale-collapsed {
  width: 46px;
  text-align: center;
}

table.editions td.locale-collapsed span {
  display: none;
}

table.editions tr.edition-published {
  background-color: lightgreen;
}

table.editions tr.new-edition {
  background-color: #ffe6e6;
}

table.editions tr.new-edition .textarea-wrapper {
  width: 100%;
  position: relative;
}

table.editions tr.new-edition th {
  vertical-align: top;
}

table.editions tr.new-edition textarea {
  width: 100%;
  width: inherit;
}

.announces h2 {
  color: white;
  //text-shadow: 0 0 0.5em #87F, 0 0 0.5em #ffF, 0 0 0.5em #87F;
  text-shadow: 2px 2px 5px #000;
}

.announce-title {

}

.announce-content {

}
.callbacks h2 {
    color: white;
    //text-shadow: 0 0 0.5em #87F, 0 0 0.5em #ffF, 0 0 0.5em #87F;
    text-shadow: 2px 2px 5px #000;
}

.callback-container {
    position: relative;
    padding-top: 1px;
    height: 231px;
    min-height: 231px;
    overflow: hidden;
}
.callback-container-collapsed {
    /*margin-top: -70px;*/
}
.callback-container-expanded {
    overflow: visible;
    height: auto;
}
/*
.callback-container-collapsed:before {
    content: "";
    display: block;
    z-index: +1;
    left: 150px;
    top: 151px;
    height: 80px;
    position: absolute;
    background-image: linear-gradient(to bottom right, rgba(255, 0, 255, 0), red 75%)
}
*/
.callback-footer {
    clear: both;
}
.callback-container .callback-photo {
    float: left;
    padding-bottom: 25px;
    padding-right: 25px;
}
.callback-content {
}
.btn-toggle-expansion
{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 30px;
    text-align: center;
    line-height: 1.42;
    border-radius: 15px;
}
.callback-container .btn {
}
#textbox{
    max-height:100px;
    overflow:hidden;
    margin-top:-20px;
}
#textbox:before{
    content:"";
    display:block;
    height:20px;
    position:relative;
    top:80px;
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 75%)
}
.map-container {
    width: 40%;
    overflow: hidden;
    height: 300px;
}

.map {
    margin-top: -150px;
    margin-bottom: -100px;
}

ul.contacts {
    padding-top: 10px;
}

ul.contacts > li {
    padding-bottom: 10px;
}

div.contacts {
    color: white;
    //text-shadow: 0 0 0.5em #87F, 0 0 0.5em #ffF, 0 0 0.5em #87F;
    text-shadow: 2px 2px 5px #000;
}
$course-back-color: darken($public-back-color, 6%);

.courses h2 {
  color: white;
  //text-shadow: 0 0 0.5em #87F, 0 0 0.5em #ffF, 0 0 0.5em #87F;
  text-shadow: 2px 2px 5px #000;
}

.course-row {
    display: flex;
    flex-direction: row;
  flex-wrap: wrap;
  //@extend .col-sm-4;
  //padding: 0 15px 0 15px;
  //padding: 0 8px 0 8px;
  //margin-left: -70px;
  //margin-right: -70px;
}

.course-container {
  flex: 1 300px;
  //float: left;
  //margin: 20px;
  //background-color: grey;
  //padding-bottom: 100%;
  //margin-bottom: -100%;
  //@extend .col-sm-4;
  margin: 10px;
  //margin: 10px;
}

.course {
  //@extend .col-sm-12;
  //border: 1px solid red;
  //background-color: transparent;
  //background-color: $public-back-color;
  //background-image: linear-gradient(to bottom, $course-back-color 0%, $course-back-color 100%);
  //background-image: linear-gradient(to bottom, rgba(30, 87, 153, 0.2) 0%, rgba(125, 185, 232, 0) 100%);
  //background-repeat: repeat;
  //border-radius: 10px;
  //margin-top: 15px;
  //padding: 15px;
  //background-clip: padding-box;
  //border: 15px solid transparent;
}

.course hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.course-title {
  font-weight: bold;
  font-size: 2em;
}

.course-description {

}

.course-duration {
  font-weight: bold;
}

.course-price {
  font-weight: bold;
  font-size: 1.5em;
  text-align: right;
}
.logo-container {
    width: 100%;
    text-align: center;
}

.logo-container img {
    //-webkit-filter: drop-shadow(12px 12px 7px rgba(255, 255, 255, 0.8));
    filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 1));
}

.main-text {
    color: white;
    //text-shadow: 0 0 0.5em #87F, 0 0 0.5em #ffF, 0 0 0.5em #87F;
    text-shadow: 2px 2px 5px #000;
}

.login-dialog .well {
    margin-bottom: 0px;
}

.login-error {
    padding: 10px;
}
.teachers h2 {
    color: white;
    //text-shadow: 0 0 0.5em #87F, 0 0 0.5em #ffF, 0 0 0.5em #87F;
    text-shadow: 2px 2px 5px #000;
}

.teacher-row {
    //display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.teacher-container {
    flex: 1 300px;
    //float: left;
    //margin: 20px;
    //background-color: grey;
    //padding-bottom: 100%;
    //margin-bottom: -100%;
    //@extend .col-sm-4;
    margin: 10px;
    //margin: 10px;
}
.teacher-container-collapsed {
    /*margin-top: -70px;*/
}
.teacher-container-expanded {
    overflow: visible;
    height: auto;
}
/*
.teacher-container-collapsed:before {
    content: "";
    display: block;
    z-index: +1;
    left: 150px;
    top: 151px;
    height: 80px;
    position: absolute;
    background-image: linear-gradient(to bottom right, rgba(255, 0, 255, 0), red 75%)
}
*/
.teacher-footer {
    clear: both;
}
.teacher-container .teacher-photo {
    float: left;
    padding-bottom: 25px;
    padding-right: 25px;
}
.teacher-content {
}
.btn-toggle-expansion
{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 30px;
    text-align: center;
    line-height: 1.42;
    border-radius: 15px;
}
.teacher-container .btn {
}
#textbox{
    max-height:100px;
    overflow:hidden;
    margin-top:-20px;
}
#textbox:before{
    content:"";
    display:block;
    height:20px;
    position:relative;
    top:80px;
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 75%)
}
body.foe.authorized [foe] {
    -webkit-animation: foe-keyframes 0.3s infinite alternate;
    -moz-animation: foe-keyframes 0.3s infinite alternate;
    -ms-animation: foe-keyframes 0.3s infinite alternate;
    -o-animation: foe-keyframes 0.3s infinite alternate;
    animation: foe-keyframes 0.3s infinite alternate;
}

@-webkit-keyframes foe-keyframes {
    from { background-color: orange; }
    to { background-color: lightgreen; }
}
@-moz-keyframes foe-keyframes {
    from { background-color: orange; }
    to { background-color: lightgreen; }
}
@-ms-keyframes foe-keyframes {
    from { background-color: orange; }
    to { background-color: lightgreen; }
}
@-o-keyframes foe-keyframes {
    from { background-color: orange; }
    to { background-color: lightgreen; }
}
@keyframes foe-keyframes {
    from { background-color: orange; }
    to { background-color: lightgreen; }
}

.foe-dialog .well {
    margin-bottom: 0px;
}

.foe-dialog input {
    width: 100%;
}

.foe-dialog table.foe
{
    border-spacing: 5px;
    border-collapse: separate;
    width: 100%;
}

.foe-dialog table.foe td,
.foe-dialog table.foe th
{
    //width: 33%;
}

.foe-dialog table.foe td {
    vertical-align: top;
}
.foe-dialog table.foe td input,
.foe-dialog table.foe td [ui-tinymce]
{
    width: 100%;
    color: $public-fore-color;
    background-color: $public-back-color;
    //border: 1px solid $public-fore-color;
    min-height: 20px;
    min-width: 20px;
    border: 1px solid $public-fore-color;
}

.foe-dialog table.foe td [ui-tinymce].mce-edit-focus {
    border: 1px solid red;
}

#tinymce-toolbar-container {
    min-height: 73px;
    content: 'no editor selected'
}


/*pagination.scss*/

.select-page {
  width: 50px;
  text-align: center;
}

.pagination li {
  cursor: pointer;
}

.pagination li a input {
  padding: 0;
  margin: -5px 0;
}
/*table-entities.scss*/
tr.entity td {
  cursor: pointer;
}

.entity-state {
  @extend .fa;
//  @extend .fa-lg;
}

$published-background-color: lighten(lightgreen, 20%);

.a {
  color: lightgreen;
}
$states: (
        published: (
                icon: fa-eye,
                color: forestgreen,
                background-color-even: lighten($published-background-color, 3%),
                background-color-odd: $published-background-color
        ),
        unpublished: (
                icon: fa-ban,
                color: orangered
        //,
        //        background-color-even: $unpublished-background-color,
        //        background-color-odd: lighten($unpublished-background-color, 50%)
        ),
        edited: (
                icon: fa-edit,
                color: red
        //,
        //        background-color-even: green,
        //        background-color-odd: lighten(green, 50%)
        ),
        unedited: (
                icon: fa-edit,
                color: gray
        //,
        //        background-color-even: green,
        //        background-color-odd: lighten(green, 50%)
        )
);

@each $state, $style in $states {

  i.entity-#{$state} {
    @extend .entity-state;
    @extend .#{map-get($style, icon)};
    color: #{map-get($style, color)};
  }

  i.edition-#{$state} {
    @extend .entity-state;
    @extend .#{map-get($style, icon)};
    color: #{map-get($style, color)};
  }

  table.entities>tbody>tr:nth-child(even).entity-#{$state} {
    background-color: #{map-get($style, background-color-even)};
  }

  table.entities>tbody>tr:nth-child(odd).entity-#{$state} {
    background-color: #{map-get($style, background-color-odd)};
  }

}
